import React, { useState, useEffect } from 'react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import {
  Chart,
  LineController, BarController, PieController,
  CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement,
  Title, Tooltip, Legend
} from 'chart.js';
import { Grid, Paper, Typography, Box, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { fadeIn } from '../Styles';

// Register the necessary chart components
Chart.register(
  LineController, BarController, PieController,
  CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement,
  Title, Tooltip, Legend
);

// Sample data-fetching function (replace with real API call)
const fetchDashboardData = async (startDate, endDate) => {
  return {
    totalUsers: [50, 100, 150, 200, 250, 300],  // Example data
    premiumUsers: [5, 10, 15, 20, 25, 30],      // Example data
    connections: [10, 20, 30],                  // Example data
    labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6'],  // Example labels
  };
};

export const Dashboard = () => {
  const [startDate, setStartDate] = useState(dayjs().subtract(30, 'days'));  // Default to 30 days ago
  const [endDate, setEndDate] = useState(dayjs()); // Default to today
  const [dashboardData, setDashboardData] = useState({});

  useEffect(() => {
    fetchDashboardData(startDate, endDate).then(data => {
      setDashboardData(data);
    });
  }, [startDate, endDate]);

  // Keyframe animation for fade-in
  

  return (
    <Box sx={{ minHeight: "90vh",padding:{xs:"0.5rem",md:"2rem"}, animation: 'fadeIn 1s ease-in-out', ...fadeIn }}>
      <Box sx={{  mb: 4 , display: {md:"flex"}, width:"100%", justifyContent:"space-between",alignItems:"center"}}>
        <Typography textAlign="start" variant="h4" gutterBottom>
          Admin Dashboard
        </Typography>

        {/* Date range picker */}
        <Box sx={{ display:'flex'}} gap={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </LocalizationProvider>
        </Box>
      </Box>

      {/* Summary Section */}
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Summary (Last {endDate.diff(startDate, 'days')} Days)
        </Typography>
        <Typography variant="body1">
          Total Users: {dashboardData.totalUsers ? dashboardData.totalUsers.reduce((a, b) => a + b, 0) : 0} <br />
          Premium Users: {dashboardData.premiumUsers ? dashboardData.premiumUsers.reduce((a, b) => a + b, 0) : 0} <br />
          Connections Created: {dashboardData.connections ? dashboardData.connections.reduce((a, b) => a + b, 0) : 0}
        </Typography>
      </Paper>

      {/* Chart Container */}
      <Grid container spacing={4}>
        {/* Total Users Line Chart */}
        <Grid item xs={12} md={12}>
          <Paper elevation={3} sx={{ p: 3, height: { xs: '40vh', md: '60vh' } }}>
            <Typography variant="h6" gutterBottom>
              Total Users Over Time
            </Typography>
            {dashboardData.totalUsers && (
              <Box sx={{width:"90%", height:"90%"}}>
                <Line
                  data={{
                    labels: dashboardData.labels,
                    datasets: [
                      {
                        label: 'Total Users',
                        data: dashboardData.totalUsers,
                        borderColor: 'rgba(75, 192, 192, 1)',
                        fill: false,
                      }
                    ]
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                      }
                    }
                  }}
                />
              </Box>
            )}
          </Paper>
        </Grid>

        {/* Premium Users Bar Chart */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: { xs: '40vh', md: '60vh' } }}>
            <Typography variant="h6" gutterBottom>
              Premium Users
            </Typography>
            {dashboardData.premiumUsers && (
              <Box sx={{width:"90%", height:"90%"}}>
                <Bar
                  data={{
                    labels: dashboardData.labels,
                    datasets: [
                      {
                        label: 'Premium Users',
                        data: dashboardData.premiumUsers,
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                      }
                    ]
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                      }
                    }
                  }}
                />
              </Box>
            )}
          </Paper>
        </Grid>

        {/* Connections Created Pie Chart */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: { xs: '40vh', md: '60vh' } }}>
            <Typography variant="h6" gutterBottom>
              Connections Created
            </Typography>
            {dashboardData.connections && (
              <Box sx={{width:"90%", height:"90%"}}>
                <Pie
                  data={{
                    labels: ['Connection 1', 'Connection 2', 'Connection 3'],
                    datasets: [
                      {
                        data: dashboardData.connections,
                        backgroundColor: ['rgba(54, 162, 235, 0.5)', 'rgba(255, 206, 86, 0.5)', 'rgba(75, 192, 192, 0.5)'],
                      }
                    ]
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                  }}
                />
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Detailed Section */}
      {/* <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Details
        </Typography>
        <Typography variant="body1">
          The charts above display the key metrics of your matrimonial platform over the selected date range. The line chart
          shows the total number of users signing up each day, the bar chart highlights the premium users, and the pie chart
          represents the number of connections made on the platform.
        </Typography>
      </Paper> */}
    </Box>
  );
};
