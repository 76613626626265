import React, { useEffect, useState } from 'react';
import {
  Box, Typography, CircularProgress, Grid, useMediaQuery, Card, CardContent
} from '@mui/material';
import UserApiService from '../../../services/UserApiServices';
import { useAuth } from '../../../context/AuthContext';
import Navbar from '../home/Navbar';
import Sidebar from '../home/Sidebar';
import { fadeIn } from '../Styles';
import UsersTable from '../UsersTable';

const UserSummary = ({ summary }) => {


  
  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      {summary.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
          <Card>
            <CardContent>
              <Typography variant="h6">{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</Typography>
              <Typography>Verified: {item.isVerified ? "Yes" : "No"}</Typography>
              <Typography>Count: {item.count}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const UsersProfile = () => {
  const [users, setUsers] = useState([]);
  const [usersSummary, setUsersSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const isSmallScreen = useMediaQuery('(max-width: 700px)');
  const { isSidebarOpen } = useAuth();
  

  useEffect(() => {
    fetchUsers(page + 1);
    fetchUserSummary();
  }, [page]);

  const fetchUsers = async (pageNumber) => {
    setLoading(true);
    try {
      const response = await UserApiService.getAllUsers(pageNumber);
      setUsers(response.users);
      setTotalPages(response.totalPages);
    } catch (err) {
      console.error('Error fetching users:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserSummary = async () => {
    setLoading(true);
    try {
      const response = await UserApiService.getUserSummary();
      setUsersSummary(response);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Navbar />
      <Sidebar />

      <Box sx={{ backgroundColor: "#F0F8FF", p: 3 }}>
        <Box
          component="main"
          sx={{
            marginTop: "4rem",
            marginLeft: !isSmallScreen && isSidebarOpen ? '300px' : '0px',
            width: !isSmallScreen && isSidebarOpen ? 'calc(100% - 300px)' : '100%',
            transition: 'margin-left 0.3s ease-in-out, width 0.3s ease-in-out',
          }}
          open={isSidebarOpen}
          className="Box"
        >
          <Box sx={{ animation: 'fadeIn 1s ease-in-out', ...fadeIn }}>
            <Typography variant="h4" sx={{ mb: 5, textAlign: 'start' }}>
              {`All Users (${users?.length}) `}
            </Typography>

            {/* Display user summary */}
            <UserSummary summary={usersSummary} />

            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <UsersTable
                users={users}
                page={page}
                rowsPerPage={rowsPerPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UsersProfile;
