import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import CoursesApiService from '../../../services/CoursesApiServices';
import { useNavigate } from 'react-router-dom';
import CategoryCard from './CategoryCard';

const DirectoryCard = () => {
  const navigate = useNavigate();
  const [categoryFilter, setCategoryFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [courses, setCourses] = useState([]);
  const isMdOrLg = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const onCourseSelect = (course) => {
    navigate(`/courses-details/${course._id}`);
  };

  const onCourseLearn = (course) => {
    navigate(`/course/${course._id}/videos`);
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const res = await CoursesApiService.getAllCourses();
        setCourses(res.courses);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCourses();
  }, []);

  const filteredCourses = courses
    .filter((course) => (categoryFilter ? course.category === categoryFilter : true))
    .filter((course) => (typeFilter ? course.type === typeFilter : true))
    .sort((a, b) => {
      if (sortOrder === 'priceAsc') {
        return parseFloat(a.pricing?.replace('$', '')) - parseFloat(b.pricing?.replace('$', ''));
      }
      return 0;
    });

  const groupByCategory = (courses) => {
    return courses.reduce((groups, course) => {
      if (!groups[course.category]) {
        groups[course.category] = [];
      }
      groups[course.category].push(course);
      return groups;
    }, {});
  };

  const groupedCourses = groupByCategory(filteredCourses);
  const uniqueCategories = [...new Set(courses.map((course) => course.category))];

  return (
    <Box sx={{ minHeight: '80vh', overflow: 'auto', backgroundColor: '#F5F5F5', display: { md: 'flex' }, position: 'relative' }}>
      <Box sx={{ padding: 2, flexGrow: 1 }}>
        <Typography fontWeight={550} mb={5} textAlign="center" color="#FF6F2F" variant="h3" gutterBottom>
          All Courses
        </Typography>

        {Object.keys(groupedCourses).map((category) => (
          <Box key={category} sx={{ marginBottom: 4 }}>
            <Typography
              textAlign="start"
              variant="h5"
              fontWeight="bold"
              color="gray"
              sx={{ marginBottom: 2, ml: 2 }}
            >
              {category}
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              {groupedCourses[category].map((course) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={course.id}>
                  <CategoryCard course={course} onCourseSelect={onCourseSelect} onCourseLearn={onCourseLearn} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default DirectoryCard;
