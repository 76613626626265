import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, Select, MenuItem, Box, useMediaQuery, IconButton } from '@mui/material';
import { Delete, PhotoLibrary, VideoLibrary } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'; // Import Axios
import { useAuth } from '../../../context/AuthContext';


const CourseCreation = () => {

  const [courseData, setCourseData] = useState({
    title: '',
    description: '',
    category: '',
    type: '',
    price: '',
    thumbnail: null,
  });
  const { userId } = useAuth();
  const [modules, setModules] = useState([
    {
      title: '',
      description: '',
      videos: [
        {
          title: '',
          url: '',
          file: null,
          preview: '',
          objective: '',
          breakpoints: [],
        },
      ],
    },
  ]);

  const handleModuleChange = (index, field, value) => {
    setModules((prevModules) => {
      const updatedModules = [...prevModules];
      updatedModules[index][field] = value;
      return updatedModules;
    });
  };

  const handleVideoChange = (moduleIndex, videoIndex, field, value, file = null) => {
    setModules((prevModules) => {
      const updatedModules = [...prevModules];
      const video = updatedModules[moduleIndex].videos[videoIndex];

      video[field] = value;

      if (file) {
        if (video.preview) {
          URL.revokeObjectURL(video.preview); // Cleanup previous URL
        }
        video.preview = URL.createObjectURL(file);
        video.file = file;
      }

      return updatedModules;
    });
  };

  const handleBreakpointChange = (moduleIndex, videoIndex, breakpointIndex, field, value) => {
    setModules((prevModules) => {
      const updatedModules = [...prevModules];
      updatedModules[moduleIndex].videos[videoIndex].breakpoints[breakpointIndex][field] = value;
      return updatedModules;
    });
  };

  const addModule = () => {
    setModules((prevModules) => [
      ...prevModules,
      {
        title: '',
        description: '',
        videos: [
          {
            title: '',
            url: '',
            file: null,
            preview: '',
            objective: '',
            breakpoints: [],
          },
        ],
      },
    ]);
  };

  const addVideo = (moduleIndex) => {
    setModules((prevModules) => {
      const updatedModules = [...prevModules];
      updatedModules[moduleIndex].videos.push({
        title: '',
        url: '',
        file: null,
        preview: '',
        objective: '',
        breakpoints: [],
      });
      return updatedModules;
    });
  };

  const addBreakpoint = (moduleIndex, videoIndex) => {
    setModules((prevModules) => {
      const updatedModules = [...prevModules];
      updatedModules[moduleIndex].videos[videoIndex].breakpoints.push({
        time: '',
        quiz: { question: '', options: [], correct: '' },
      });
      return updatedModules;
    });
  };

  const removeModule = (moduleIndex) => {
    setModules((prevModules) => prevModules.filter((_, idx) => idx !== moduleIndex));
  };

  const removeVideo = (moduleIndex, videoIndex) => {
    setModules((prevModules) => {
      const updatedModules = [...prevModules];
      const video = updatedModules[moduleIndex].videos[videoIndex];
      if (video.preview) {
        URL.revokeObjectURL(video.preview); // Cleanup preview URL
      }
      updatedModules[moduleIndex].videos.splice(videoIndex, 1);
      return updatedModules;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate course data
    if (!courseData.title || !courseData.description || !courseData.category || !courseData.type) {
      console.error('Please fill in all required fields.');
      return;
    }
  
    // Create FormData to handle both JSON data and file uploads
    const formData = new FormData();
    formData.append('id', userId);
    formData.append('title', courseData.title);
    formData.append('description', courseData.description);
    formData.append('category', courseData.category);
    formData.append('type', courseData.type);
  
    if (courseData.type === 'premium') {
      formData.append('price', courseData.price);
    }
     // Append thumbnail (if available)
  if (courseData.thumbnail) {
    formData.append('thumbnail', courseData.thumbnail);
  }
  
    // Append modules and videos
    modules.forEach((module, moduleIndex) => {
      const moduleData = {
        id: module.id || uuidv4(),
        title: module.title,
        description: module.description,
        videos: module.videos.map((video) => ({
          id: video.id || uuidv4(),
          title: video.title,
          objective: video.objective,
          breakpoints: video.breakpoints.map((breakpoint) => ({
            time: breakpoint.time,
            quiz: {
              question: breakpoint.quiz.question,
              correct: breakpoint.quiz.correct,
            },
          })),
        })),
      };
  
      // Append module data as JSON string
      formData.append(`modules[${moduleIndex}]`, JSON.stringify(moduleData));
  
      // Append video files
      module.videos.forEach((video, videoIndex) => {
        if (video.file) {
          formData.append(`videos[${moduleIndex}][${videoIndex}]`, video.file);
        }
      });
    });
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/api/courses/upload-course`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('Course created successfully:', response.data);
    } catch (error) {
      console.error('Error creating course:', error.response?.data || error.message);
    }
  };
  

  const handleThumbnailChange = (file) => {
    setCourseData({
      ...courseData,
      thumbnail: file,
      thumbnailPreview: file ? URL.createObjectURL(file) : '',
    });
  };


  return (
    <>

      <Box
      >
        <form onSubmit={handleSubmit}>
          <Typography variant="h4" sx={{ color: "#0F2A47" }} fontWeight={550} gutterBottom>Create New Course</Typography>

          {/* Course details */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="Title" fullWidth onChange={(e) => setCourseData({ ...courseData, title: e.target.value })} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Description" fullWidth multiline rows={4} onChange={(e) => setCourseData({ ...courseData, description: e.target.value })} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select value={courseData.category} onChange={(e) => setCourseData({ ...courseData, category: e.target.value })} fullWidth displayEmpty>
                <MenuItem value="" disabled>Select Category</MenuItem>
                <MenuItem value="Cybersecurity">Cybersecurity</MenuItem>
                <MenuItem value="Web Development">Web Development</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select value={courseData.type} onChange={(e) => setCourseData({ ...courseData, type: e.target.value })} fullWidth displayEmpty>
                <MenuItem value="" disabled>Select Type</MenuItem>
                <MenuItem value="free">Free</MenuItem>
                <MenuItem value="premium">Premium</MenuItem>
              </Select>
            </Grid>
            {courseData.type === 'premium' && (
              <Grid item xs={12}>
                <TextField
                  label="Price"
                  fullWidth
                  type="number"
                  value={courseData.price}
                  onChange={(e) => setCourseData({ ...courseData, price: e.target.value })}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h6">Upload Course Thumbnail</Typography>
              <Button sx={{ mt: 1 }} variant="contained" component="label" startIcon={<PhotoLibrary />}>
                Upload Thumbnail
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => handleThumbnailChange(e.target.files[0])}
                />
              </Button>
              {courseData.thumbnailPreview && (
                <Box sx={{ marginTop: 2 }}>
                  <Typography variant="caption">Thumbnail Preview:</Typography>
                  <Box sx={{ height: '20vh', marginTop: '0.5rem' }}>
                    <img src={courseData.thumbnailPreview} alt="Thumbnail Preview" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </Box>
                </Box>
              )}
            </Grid>

          </Grid>

          {/* Modules and videos */}
          <Typography variant="h5" gutterBottom sx={{ marginTop: '1.5rem' }}>Modules</Typography>
          {modules.map((module, moduleIndex) => (
            <Box key={moduleIndex} sx={{ border: '1px solid #ccc', padding: '1rem', marginBottom: '1rem', borderRadius: '8px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField label="Module Title" value={module.title} fullWidth onChange={(e) => handleModuleChange(moduleIndex, 'title', e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Module Description" value={module.description} fullWidth onChange={(e) => handleModuleChange(moduleIndex, 'description', e.target.value)} />
                </Grid>
              </Grid>

              {/* Videos */}
              <Typography variant="h6" gutterBottom sx={{ marginTop: '1rem' }}>Videos</Typography>
              {module.videos.map((video, videoIndex) => (
                <Box key={videoIndex} sx={{ border: '1px solid #ddd', padding: '0.5rem', marginBottom: '1rem' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField label="Video Title" value={video.title} fullWidth onChange={(e) => handleVideoChange(moduleIndex, videoIndex, 'title', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField label="Video Objective" value={video.objective} fullWidth onChange={(e) => handleVideoChange(moduleIndex, videoIndex, 'objective', e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" component="label" startIcon={<VideoLibrary />}>Upload Video
                        <input type="file" accept="video/*" hidden onChange={(e) => handleVideoChange(moduleIndex, videoIndex, 'file', e.target.files[0], e.target.files[0])} />
                      </Button>
                    </Grid>
                    {video.preview && (
                      <Grid item xs={12}>
                        <Typography variant="caption" display="block" gutterBottom>Video Preview</Typography>
                        <Box sx={{ height: "30vh" }}>
                          <video controls src={video.preview} style={{ width: '100%', height: '100%' }}></video>
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <IconButton onClick={() => removeVideo(moduleIndex, videoIndex)} color="error">
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>

                  {/* Breakpoints */}
                  <Typography variant="body1" gutterBottom sx={{ marginTop: '1rem' }}>Breakpoints</Typography>
                  {video.breakpoints.map((breakpoint, breakpointIndex) => (
                    <Grid container spacing={2} key={breakpointIndex}>
                      <Grid item xs={12} sm={4}>
                        <TextField label="Breakpoint Time" value={breakpoint.time} fullWidth onChange={(e) => handleBreakpointChange(moduleIndex, videoIndex, breakpointIndex, 'time', e.target.value)} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField label="Quiz Question" value={breakpoint.quiz.question} fullWidth onChange={(e) => handleBreakpointChange(moduleIndex, videoIndex, breakpointIndex, 'quiz', { ...breakpoint.quiz, question: e.target.value })} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField label="Correct Answer" value={breakpoint.quiz.correct} fullWidth onChange={(e) => handleBreakpointChange(moduleIndex, videoIndex, breakpointIndex, 'quiz', { ...breakpoint.quiz, correct: e.target.value })} />
                      </Grid>
                    </Grid>
                  ))}
                  <Button variant="outlined" color="primary" onClick={() => addBreakpoint(moduleIndex, videoIndex)} sx={{ marginTop: '0.5rem' }}>Add Breakpoint</Button>
                </Box>
              ))}
              <Box gap={2} display="flex">
                <Button variant="contained" onClick={() => addVideo(moduleIndex)} sx={{ marginTop: '0.5rem' }}>Add Video</Button>
                <Button variant="outlined" color="error" onClick={() => removeModule(moduleIndex)} sx={{ marginTop: '0.5rem' }}>Remove Module</Button>
              </Box>
            </Box>
          ))}
          <Box gap={2} display="flex">
            <Button variant="contained" color="secondary" onClick={addModule}>Add Module</Button>

            <Button variant="contained" color="primary" type="submit" >Create Course</Button>
          </Box>

        </form>
      </Box>
    </>
  );
};

export default CourseCreation;
