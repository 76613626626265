import * as React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Background } from '../ColorCodes';
import { CONST_ROUTE_PATH } from '../../../routes/Routes';
import { useAuth } from '../../../context/AuthContext';
import Logo2 from '../../../assets/Logo.png'
import { Divider } from '@mui/material';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,

}));

export default function Navbar() {
  const { toggleSidebar,logout } = useAuth();
  const navigate = useNavigate()
  

  const handellogout = () => {
    logout()
    navigate(CONST_ROUTE_PATH.HOME)
  }
  return (
    // <Box sx={{ flexGrow: 1 }}>
    <AppBar position="fixed" sx={{ backgroundColor: "#FFF5EE", }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between", color: "black" }} >
        <Box sx={{ display: "flex" }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleSidebar}
          >
            <MenuIcon sx={{ color: '#FF6F2F' }} />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              height="80px"
              src={Logo2}
              alt="logo"
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/')}
            />
            <Box sx={{ ml: "-2rem", textAlign: 'center' }}>
              <Typography variant='h6' color='#0F2A47' sx={{ fontStyle: 'italic', fontWeight: 550, fontSize: "1rem", p: 0 }}>INSTITUTE OF</Typography>
              <Divider />
              <Typography variant='h6' color='#0F2A47' sx={{ fontStyle: 'italic', fontWeight: 550, fontSize: "1rem", p: 0 }}>CYBER SECURITY</Typography>
            </Box>
          </Box>
        </Box>

        <Button onClick={handellogout} sx={{fontWeight:600}} color="inherit">Logout</Button>
      </Toolbar>
    </AppBar>
    // </Box>
  );
}
