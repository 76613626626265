import { REGISTRATION_TYPE } from "../components/common/Helper";
import APIConstants from "./ApiConstant";
import APIService from "./ApiServices";

/**
 * @description Login Api Service
 */
const LoginApiService = {
  // Manual Login
  manual: async (data) => {
    try {
      return await APIService.post(APIConstants.ENDPOINT_SIGNIN, data)
    } catch (err) {
      throw err
    }
  },

};

export default LoginApiService;
