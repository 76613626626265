import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import { useAuth } from '../../../context/AuthContext';
import { CONST_ROUTE_PATH } from '../../../routes/Routes';

const drawerWidth = 300;

const menus = {
    courseManagement: [
        { label: "Courses", path:CONST_ROUTE_PATH.COURSES, icon: <AccountTreeRoundedIcon /> },
        { label: "Create courses", path: CONST_ROUTE_PATH.CREATE_COURSES, icon: <StorageRoundedIcon /> },
        { label: "Courses Feedback", path: '/messages-overview', icon: <AccountTreeRoundedIcon /> },
    ],
    userManagement: [
        { label: "User Profiles", path: CONST_ROUTE_PATH.USER_PROFILE, icon: <AccountTreeRoundedIcon /> },
        { label: "Profile Moderation", path: '/profile-moderation', icon: <StorageRoundedIcon /> },
        { label: "Messages Overview", path: '/messages-overview', icon: <AccountTreeRoundedIcon /> },
    ],
    contentManagement: [
        { label: "Blog/Articles", path: '/blog-articles', icon: <AccountTreeRoundedIcon /> },
        { label: "FAQs & Help Center", path: '/faqs-help', icon: <StorageRoundedIcon /> },
    ],

    RolesPermissions: [
        { label: "Admin Users", path: '/admin-users', icon: <AccountTreeRoundedIcon /> },
    ],
    settings: [
        { label: "Site Settings", path: '/site-settings', icon: <AccountTreeRoundedIcon /> },
        { label: "Email Settings", path: '/email-settings', icon: <AccountTreeRoundedIcon /> },
    ]
};

const StyledNavLink = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    color: 'white',
    '&.active': {
        // backgroundColor: 'rgb(0, 0, 0,1)', // Active background color
        '& .MuiTypography-root': {
            color: '#FF6F2F',
        },
        '& .MuiListItemIcon-root': {
            color: '#FF6F2F', // Active icon color
        },
        
    },
}));

function MenuAccordion({ title, menuItems }) {
    return (
        <Accordion sx={{ backgroundColor: '#FFF5EE', color: "black" }} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${title}-content`} id={`${title}-header`}>
                <Typography variant='body1' color="#FF6F2F" fontSize={18} fontWeight={550}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List sx={{ marginTop: "-1rem" }}>
                    {menuItems?.map(({ label, path, icon }) => (
                        <ListItem key={label} disablePadding>
                            <ListItemButton component={StyledNavLink} to={path} sx={{ width: "100%" }}>
                                <ListItemIcon >{icon}</ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2" fontWeight={600}>{label}</Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    );
}

export default function Sidebar() {
    const { isSidebarOpen } = useAuth();  // Access the open state from context

    return (
        <>
            <CssBaseline />
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: '#FFF5EE',
                        overflowY: 'scroll',
                        scrollbarWidth: 'none', // Hide scrollbar in Firefox
                        '&::-webkit-scrollbar': {
                            display: 'none', // Hide scrollbar in Chrome, Safari, and Edge
                        },
                    },
                   
                }}
                variant="persistent"
                anchor="left"
                open={isSidebarOpen}
            >
                <Box sx={{ marginTop: '3rem', }}>
                    <List sx={{ marginTop: '2rem' }}>
                        <ListItem disablePadding>
                            <ListItemButton component={StyledNavLink} to={CONST_ROUTE_PATH.DASHBOARD} sx={{ width: "100%" }}>
                                <ListItemIcon >
                                    <DashboardIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body1" color="black" fontWeight={600}>Dashboard</Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                    <MenuAccordion title="Courses Management" menuItems={menus.courseManagement} />
                    <MenuAccordion title="User Management" menuItems={menus.userManagement} />
                    <MenuAccordion title="Content Management" menuItems={menus.contentManagement} />
                    <MenuAccordion title="Settings" menuItems={menus.settings} />
                </Box>
            </Drawer>
        </>
    );
}
