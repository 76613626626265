const APIConstants = {
    // User Endpoints
    ENDPOINT_REGISTRATION: 'api/auth/register/user',
    ENDPOINT_SIGNIN: 'api/auth/login/admin',
    ENDPOINT_SEARCH_USERS: 'api/admin/get-users',
    ENDPOINT_SEND_OTP: 'api/auth/otp/send',
    ENDPOINT_VERIFY_OTP: 'api/auth/otp/verify',
    ENDPOINT_VERIFY_USER:"api/auth/verify/user",
    ENDPOINT_USER_DETAILS:"api/admin/user",
    ENDPOINT_USER_DETAIL:"api/admin/user",
    ENDPOINT_USER_SUMMARY:"api/admin/user/summary",
    ENDPOINT_COURSES_ID:"api/courses/get/courses/id",
    ENDPOINT_ALL_COURSES:"api/courses/get/courses",
    ENDPOINT_DELETE_COURSES:"api/courses/delete",
    

    
};

export default APIConstants;
