import APIConstants from "./ApiConstant";
import APIService from "./ApiServices";


const UserApiService = {

   // Fetch user details by ID
   getUserById: async (userId) => {
    try {
      const endpoint = `${APIConstants.ENDPOINT_USER_DETAIL}?userId=${userId}`;
      return await APIService.get(endpoint);
    } catch (err) {
      console.error('UserApiService: Error fetching user details by ID', err);
      throw err; // Optionally re-throw to handle in calling code
    }
  },

  // Fetch user details by email
  BanedUser: async (userId) => {
    try {
      const endpoint = `${APIConstants.ENDPOINT_USER_DETAILS}?userId=${userId}`;
      return await APIService.get(endpoint);
    } catch (err) {
      console.error('UserApiService: Caught error while fetching user details', err);
      throw err; // Optionally re-throw to handle in calling code
    }
  },
  //getAllUsers
  getAllUsers: async (page) => {
    try {
      const endpoint = `${APIConstants.ENDPOINT_SEARCH_USERS}?page=${page}`;
      const response = await APIService.get(endpoint);
      return response.data;
    } catch (err) {
      console.error('Error while fetching users', err);
      throw err; // Optionally re-throw to handle in the calling code
    }
  },
  getUserSummary:async()=>{
    try {
      const endpoint = `${APIConstants.ENDPOINT_USER_SUMMARY}`;
      const response = await APIService.get(endpoint);
      return response.data;
    } catch (err) {
      console.error('Error while fetching users', err);
      throw err; // Optionally re-throw to handle in the calling code
    }
  },
  //SerachUser 
  SearchAllUser: async (location,gender,ageRange,  religion , language,UserId) => {
    try {
      const endpoint = `${APIConstants.ENDPOINT_SEARCH_USER}?location=${location}&gender=${gender}&ageRange=${JSON.stringify(ageRange)}&religion=${religion}&language=${language}&userId=${UserId}`;
      return await APIService.get(endpoint);
    } catch (err) {
      console.error('UserApiService: Caught error while fetching ', err);
      throw err; // Optionally re-throw to handle in calling code
    }
  },
  


  // Update user details
  UserStatusUpdate: async (userData, apiToken) => {
    try {
    //   const headers = {
    //     'Authorization': `Bearer ${apiToken}`, // Assuming you use Bearer token for auth
    //     'Content-Type': 'application/json',
    //   };
      return await APIService.put(APIConstants.ENDPOINT_UPDATE_USER,userData);
    } catch (err) {
      console.error('UserApiService: Caught error while updating user details', err, userData);
      throw err; // Optionally re-throw to handle in calling code
    }
  }
  
};


export default UserApiService;
