import React from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CONST_ROUTE_PATH } from './routes/Routes';
import Signin from './components/SignIn';
import Navbar from './components/common/home/Navbar';
import Footer from './components/common/home/Footer';
import Admin from './components/Admin'
import { UserContextProvider } from './context/UserContext';
import UserDetails from './components/common/pages/userhandler/UserDetails';
import UsersProfile from './components/common/pages/UsersProfile';
import Welcome from './components/common/pages/Welcome';
import CourseCreation from './components/common/pages/CourseCreation';
import AboutPage from './components/common/pages/AboutPage';
import ProtectedRoute from './routes/ProtectedRoute';
import DirectoryCard from './components/common/content/DirectoryCard';
import Structure from './components/common/pages/Structure';
import UpdateCourses from './components/common/pages/UpdateCourses';



function App() {
   const theme = useTheme()
  return (
    <>
     <ThemeProvider theme={theme}>
      {/* // <AppContextProvider>
        // <ConfirmProvider> */}
        <UserContextProvider>

            <BrowserRouter>
            {/* <Navbar/> */}
              <Routes>
                <Route path={CONST_ROUTE_PATH.HOME} element={<Welcome/>}  />

                <Route path={CONST_ROUTE_PATH.SIGN_IN}  element={<Signin/>} />
                <Route path={CONST_ROUTE_PATH.DASHBOARD}  element={<ProtectedRoute><Admin/></ProtectedRoute>} />
                <Route path={CONST_ROUTE_PATH.CREATE_COURSES} element={<ProtectedRoute><Structure><CourseCreation /></Structure></ProtectedRoute>} /> {/* User details page */}
                <Route path={CONST_ROUTE_PATH.COURSES} element={<ProtectedRoute><Structure><DirectoryCard /></Structure></ProtectedRoute>} /> 
                <Route path="/course/:courseId" element={<ProtectedRoute><Structure><UpdateCourses /></Structure></ProtectedRoute>} /> {/* User details page */}

                


                <Route path={CONST_ROUTE_PATH.USER_PROFILE} element={<UsersProfile/>}  />
                <Route path="" element={<UpdateCourses />} /> {/* User details page */}


              
                {/* <Route path="*" Component={PageNotFound} /> */}
              </Routes>
              {/* <Footer/> */}
            </BrowserRouter>
            </UserContextProvider>

        {/* // </ConfirmProvider> */}
      {/* </AppContextProvider> */}
     </ThemeProvider>
    </>
  );
}

export default App;
