import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TableSortLabel, TablePagination, Box, TextField, IconButton
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Link } from 'react-router-dom';

const UsersTable = ({ users, page, rowsPerPage, totalPages, onPageChange }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('firstname');
    const [filter, setFilter] = useState('');
  
    const handleSort = (property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    // Filter users by name based on filter input
    const filteredUsers = users.filter(user => 
      `${user.firstname} ${user.lastname}`.toLowerCase().includes(filter.toLowerCase())
    );
  
    // Sort filtered users based on current sorting order
    const sortedUsers = [...filteredUsers].sort((a, b) => {
      if (orderBy === 'age') {
        return order === 'asc' ? a.age - b.age : b.age - a.age;
      } else {
        return order === 'asc'
          ? a[orderBy]?.localeCompare(b[orderBy])
          : b[orderBy]?.localeCompare(a[orderBy]);
      }
    });
  
    return (
        <>
        <Box display="flex" alignItems="center" justifyContent="space-between" p={2}  mb={2}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search Users"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            sx={{ width: '250px' }}
          />
          <IconButton onClick={() => setFilter('')}>
            <FilterListIcon />
          </IconButton>
        </Box>
      <TableContainer component={Paper} sx={{ borderRadius: "1vw",height: "60vh"}}>
        {/* Search Input with Filter Icon */}
        
  
        {/* Custom Table Headers */}
        <Table stickyHeader aria-label="sticky table"  >
          <TableHead >
            <TableRow>
              <TableCell>
                <TableSortLabel active={orderBy === 'firstname'} direction={order} onClick={() => handleSort('firstname')}>
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel active={orderBy === 'age'} direction={order} onClick={() => handleSort('age')}>
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel active={orderBy === 'gender'} direction={order} onClick={() => handleSort('gender')}>
                  membership
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
  
          {/* Table Body */}
          <TableBody >
            {sortedUsers.length > 0 ? (
              sortedUsers.map(user => (
                <TableRow key={user._id}>
                  <TableCell>
                    <Link to={`user-details/${user._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      {`${user.firstname} ${user.lastname}`}
                    </Link>
                    
                  </TableCell>
                  <TableCell>{user.age}</TableCell>
                  <TableCell>{user.gender}</TableCell>
                  <TableCell>{`${user.address?.city}, ${user.address?.state}`}</TableCell>
                  <TableCell>{user.religion}</TableCell>
                  <TableCell>{user.language}</TableCell>
                  <TableCell>{user.salary}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No users found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
  
        {/* Pagination */}
        <TablePagination
          component="div"
          count={totalPages * rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
      </TableContainer>
      </>

    );
  };

  export default UsersTable