import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, TextField, Link, Box, Divider, Avatar } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { CONST_ROUTE_PATH } from '../routes/Routes';
import { REGISTRATION_TYPE } from './common/Helper';
import LoginApiService from '../services/LoginApiService';
import { useAuth } from '../context/AuthContext';
import HomeIcon from '@mui/icons-material/Home';



const Signin = () => {

  const navigate = useNavigate()
  const [error, setError] = useState(null);

  const {
    loginWithApi
  } = useAuth();

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
  });


  const handleLogin = async (values) => {
    try {
        const response = await LoginApiService.manual(values);
        if (response && response.token) {
            const { token, userId } = response;
            loginWithApi(token, userId);
            localStorage.setItem('email', values.email);
            navigate(CONST_ROUTE_PATH.DASHBOARD);
        }
    } catch (error) {
      console.log(error.response?.data?.message)
        setError(error.response?.data?.message?.toString());
    }
};

  return (
    <>
      <Box sx={{
        minHeight: '90vh',
        display: 'flex',
        p: 2,
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
      }}>
        <Button startIcon={<HomeIcon />} onClick={() => navigate(CONST_ROUTE_PATH.HOME)} variant='text' color='success' sx={{ position: "absolute", top: 30, left: 30, color: "black", textTransform: "none" }}>Home</Button>
        <Container maxWidth="sm" sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.5)', color: 'white', borderRadius: '2vw', p: 3, animation: 'fadeIn 0.5s ease-in-out', // Fade-in animation
          '@keyframes fadeIn': {
            '0%': {
              opacity: 0, // Start fully transparent
            },
            '100%': {
              opacity: 1, // End fully visible
            },
          },
          transition: 'all 0.3s ease-in-out',
        }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", color: 'black' }}>

            <Avatar sx={{ m: 1, bgcolor: 'green', p: 2 }}>
              <LockOutlinedIcon fontSize="large" />
            </Avatar>
            <Typography fontWeight={600} mt={2} variant="h4" gutterBottom>
              Sign In
            </Typography>

            {/* <Divider style={{ width: '80%', marginTop: "1rem", marginBottom: '0.3rem' }}><span >OR</span></Divider> */}

            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={validationSchema}
              onSubmit={(values) => handleLogin(values)}
            >
              {({ handleChange, handleBlur, handleSubmit, values, touched, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <Box mt={1}>
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                    <TextField
                      id="password"
                      name="password"
                      label="Password"
                      type="password"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Box>
                  {error && <Typography color="error" variant="body2">{error}</Typography>}

                  <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: "end" }}>
                    
                    <Button
                      sx={{ mt: 2 }}
                      variant="contained"
                      color="success"
                      type="submit"
                    >
                      Sign In
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
            <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'space-between' }}>

            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Signin;
