import { createContext, useContext,  useEffect,  useState } from 'react';
import UserApiService from '../services/UserApiServices';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [apiToken, setApiToken] = useState(localStorage.getItem('apiToken') || null);
  const [userdetails, setUserDetails]= useState(null);
  const [Tamptoken, setTamptoken]= useState(localStorage.getItem('tempToken') || null);
  const [userId , setUserId]=useState(localStorage.getItem('UserId') || null)
  // const [backendToken, setBackendToken] = useState(localStorage.getItem('backendToken') || null);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };
  const loginWithApi = (token,userId) => {
    setApiToken(token);
    setUserId(userId)
    localStorage.setItem('apiToken', token);
    localStorage.setItem('UserId', userId);
  };

  const logout = () => {
    setApiToken(null);
    localStorage.clear()
    sessionStorage.clear()
    
    // localStorage.removeItem('apiToken');
    // localStorage.removeItem('backendToken');
    // localStorage.removeItem('userDetails');
  };

  const fetchUserDetails = async (userId) => {
    try {
      const userDetails = await UserApiService.userDetails(userId);
      setUserDetails(userDetails?.data);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    if (apiToken) {
      // Fetch user details if needed, e.g., after a page refresh
      if (userId) {
        fetchUserDetails(userId);
      }
    }
  }, [apiToken]);
  return (
    <AuthContext.Provider value={{ 
      apiToken, 
      userdetails,
      Tamptoken,
      fetchUserDetails,
      setUserDetails,
      loginWithApi, 
      logout,
      setTamptoken,
      userId ,
      isSidebarOpen,         // Added dopen to context
      toggleSidebar,      
      }}>
      {children}
    </AuthContext.Provider>
  );
}
export const useAuth = () => useContext(AuthContext);