import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Paper, Button } from '@mui/material';
import { School, Group, BarChart, AddCircleOutline, People, Assessment } from '@mui/icons-material'; // MUI icons
import '../Style.css'; // Make sure this file contains the necessary CSS for animations
import Header from '../home/Header';
import CompanyValue from '../content/ComapnyValue';
import Services from '../content/Services';
import { Footer } from '../home/Footer';
import { useNavigate } from 'react-router-dom';
import { CONST_ROUTE_PATH } from '../../../routes/Routes';
import { useAuth } from '../../../context/AuthContext';

const Welcome = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const { apiToken } = useAuth()
  const navigate = useNavigate()
  const images = [
    "https://static.vecteezy.com/system/resources/previews/007/278/146/large_2x/modern-abstract-sport-geometric-collage-speed-movement-banner-or-poster-for-motion-vector.jpg"
  ];

  // Automatically change images every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [images.length]);

  useEffect(() => {
    // Trigger visibility after the component mounts
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Delay to ensure the animation plays

    return () => clearTimeout(timer);
  }, []);

  // Icon details for cards
  const cardDetails = [
    {
      title: 'Active Courses',
      description: 'Manage and review all currently active courses.',
      icon: <School fontSize="large" />
    },
    {
      title: 'User Enrollments',
      description: 'Track user enrollments and monitor course completion rates.',
      icon: <Group fontSize="large" />
    },
    {
      title: 'Instructor Performance',
      description: 'Evaluate instructor performance based on user feedback and course engagement.',
      icon: <BarChart fontSize="large" />
    },
    {
      title: 'Add New Courses',
      description: 'Easily create and publish new courses for learners.',
      icon: <AddCircleOutline fontSize="large" />
    },
    {
      title: 'User Management',
      description: 'View and manage user accounts, roles, and access privileges.',
      icon: <People fontSize="large" />
    },
    {
      title: 'Reports & Analytics',
      description: 'Generate detailed reports on course performance and user engagement.',
      icon: <Assessment fontSize="large" />
    }
  ];

  const handleClick = () => {
    if (apiToken) {
      navigate(CONST_ROUTE_PATH.DASHBOARD)

    } else {
      navigate(CONST_ROUTE_PATH.SIGN_IN)
    }
  }

  return (
    <>
      {/* Header Component */}
      <Header />

      {/* Main Banner Section */}
      <Box
        sx={{
          height: '100vh',
          backgroundImage: `url(${images[currentImageIndex]})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
        }}
      >
        {/* Content inside the banner */}
        <Box
          sx={{
            display: 'flex',
            p: 3,
            alignItems: 'center',
            height: '100%',
            color: '#fff',
            position: 'relative',
          }}
        >
          <Box
            className={isVisible ? 'welcome-slide-in' : ''}
            maxWidth="md"
            sx={{
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              p: 5,
              ml: { md: 5 },
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",

              minWidth: '30vw',
              minHeight: '20vh',
              transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
              transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
              opacity: isVisible ? 1 : 0,
            }}
          >
            <Typography variant="h4" align="center" mb={4} gutterBottom>
              ICS Admin Dashboard
            </Typography>
            <Typography variant="body1" align="center">
              Welcome to your LMS admin dashboard. Here you can manage courses, monitor user activities, and ensure smooth learning experiences for all users.
            </Typography>

            <Button onClick={handleClick} variant='contained' sx={{ mt: 2,bgcolor:"#0F2A47",fontWeight:550}} > Welcome</Button>
          </Box>
        </Box>
      </Box>

      {/* Overview and Key Actions Section */}
      {['Quick Overview', 'Key Actions'].map((sectionTitle, index) => (
        <Box key={sectionTitle} sx={{ bgcolor: '#f7f7f7', p: 4 }}>
          <Typography variant="h4" fontWeight={600} align="center" color='#0F2A47' gutterBottom>
            {sectionTitle}
          </Typography>
          <Grid container spacing={4}>
            {cardDetails.slice(index * 3, (index + 1) * 3).map((card, idx) => (
              <Grid item xs={12} sm={6} md={4} key={idx}>
                <Paper sx={{ minHeight: "20vh", p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center" }}>
                  {card.icon}
                  <Typography variant="h6" sx={{ mt: 2 }}>{card.title}</Typography>
                  <Typography variant="body1" align="center">{card.description}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}

      {/* Footer */}
      <Footer />
    </>
  );
};

export default Welcome;
