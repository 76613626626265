import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, Select, MenuItem, Box, IconButton, Divider } from '@mui/material';
import { Delete, PhotoLibrary, VideoLibrary } from '@mui/icons-material';
import { useAuth } from '../../../context/AuthContext';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CoursesApiService from '../../../services/CoursesApiServices';
import ReactPlayer from 'react-player';

const UpdateCourses = () => {
    const { userId } = useAuth();
    const { courseId } = useParams();  // Extract courseId from URL
    const [courseData, setCourseData] = useState({
        title: '',
        description: '',
        category: '',
        type: '',
        price: '',
        thumbnail: null,
        thumbnailPreview: '',
    });
    const [modules, setModules] = useState([]);

    // Fetch course data for the update
    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const response = await CoursesApiService.getCoursesbyId(courseId);
                const data = response.data[0];  // Assuming your response structure is an array

                // Pre-populate course data
                setCourseData({
                    title: data.title,
                    description: data.description,
                    category: data.category,
                    type: data.type,
                    price: data.price || '',
                    thumbnail: data.thumbnailUrl,
                    thumbnailPreview: data.thumbnailUrl, // Preview the existing thumbnail
                });

                // Pre-populate modules and videos
                setModules(data.modules);
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        fetchCourseData();
    }, [courseId]);

    const addModule = () => {
        setModules((prevModules) => [
            ...prevModules,
            {
                title: '',
                description: '',
                videos: [
                    {
                        title: '',
                        url: '',
                        file: null,
                        preview: '',
                        objective: '',
                        breakpoints: [],
                    },
                ],
            },
        ]);
    };

    const removeModule = (index) => {
        setModules((prevModules) => prevModules.filter((_, i) => i !== index));
    };

    const addVideo = (moduleIndex) => {
        setModules((prevModules) => {
            const updatedModules = [...prevModules];
            updatedModules[moduleIndex].videos.push({
                title: '',
                url: '',
                file: null,
                preview: '',
                objective: '',
                breakpoints: [],
            });
            return updatedModules;
        });
    };

    const removeVideo = (moduleIndex, videoIndex) => {
        setModules((prevModules) => {
            const updatedModules = [...prevModules];
            updatedModules[moduleIndex].videos = updatedModules[moduleIndex].videos.filter((_, i) => i !== videoIndex);
            return updatedModules;
        });
    };

    const handleModuleChange = (index, field, value) => {
        setModules((prevModules) => {
            const updatedModules = [...prevModules];
            updatedModules[index][field] = value;
            return updatedModules;
        });
    };

    const handleVideoChange = (moduleIndex, videoIndex, field, value, file = null) => {
        setModules((prevModules) => {
            const updatedModules = [...prevModules];
            const video = updatedModules[moduleIndex].videos[videoIndex];

            video[field] = value;

            if (file) {
                if (video.preview) {
                    URL.revokeObjectURL(video.preview); // Cleanup previous URL
                }
                video.preview = URL.createObjectURL(file);
                video.file = file;
            }

            return updatedModules;
        });
    };

    const handleBreakpointChange = (moduleIndex, videoIndex, breakpointIndex, field, value) => {
        setModules((prevModules) => {
            const updatedModules = [...prevModules];
            updatedModules[moduleIndex].videos[videoIndex].breakpoints[breakpointIndex][field] = value;
            return updatedModules;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate course data
        if (!courseData.title || !courseData.description || !courseData.category || !courseData.type) {
            console.error('Please fill in all required fields.');
            return;
        }

        // Create FormData to handle both JSON data and file uploads
        const formData = new FormData();
        formData.append('id', userId);
        formData.append('courseId', courseId);  // Include courseId for updating the course
        formData.append('title', courseData.title);
        formData.append('description', courseData.description);
        formData.append('category', courseData.category);
        formData.append('type', courseData.type);

        if (courseData.type === 'premium') {
            formData.append('price', courseData.price);
        }

        // Append thumbnail (if available)
        if (courseData.thumbnail) {
            formData.append('thumbnail', courseData.thumbnail);
        }

        // Append modules and videos
        modules.forEach((module, moduleIndex) => {
            const moduleData = {
                id: module.id,
                title: module.title,
                description: module.description,
                videos: module.videos.map((video) => ({
                    id: video.id,
                    title: video.title,
                    objective: video.objective,
                    breakpoints: video.breakpoints.map((breakpoint) => ({
                        time: breakpoint.time,
                        quiz: {
                            question: breakpoint.quiz.question,
                            correct: breakpoint.quiz.correct,
                        },
                    })),
                })),
            };

            // Append module data as JSON string
            formData.append(`modules[${moduleIndex}]`, JSON.stringify(moduleData));

            // Append video files
            module.videos.forEach((video, videoIndex) => {
                if (video.file) {
                    formData.append(`videos[${moduleIndex}][${videoIndex}]`, video.file);
                }
            });
        });

        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URI}/api/courses/update-course`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Course updated successfully:', response.data);
        } catch (error) {
            console.error('Error updating course:', error.response?.data || error.message);
        }
    };

    const handleThumbnailChange = (file) => {
        setCourseData({
            ...courseData,
            thumbnail: file,
            thumbnailPreview: file ? URL.createObjectURL(file) : '',
        });
    };

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <Typography variant="h4" sx={{ color: "#0F2A47" }} fontWeight={550} gutterBottom>Update Course</Typography>

                {/* Course details */}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField label="Title" fullWidth value={courseData.title} onChange={(e) => setCourseData({ ...courseData, title: e.target.value })} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Description" fullWidth multiline rows={4} value={courseData.description} onChange={(e) => setCourseData({ ...courseData, description: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select value={courseData.category} onChange={(e) => setCourseData({ ...courseData, category: e.target.value })} fullWidth>
                            <MenuItem value="Cybersecurity">Cybersecurity</MenuItem>
                            <MenuItem value="Web Development">Web Development</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select value={courseData.type} onChange={(e) => setCourseData({ ...courseData, type: e.target.value })} fullWidth>
                            <MenuItem value="free">Free</MenuItem>
                            <MenuItem value="premium">Premium</MenuItem>
                        </Select>
                    </Grid>
                    {courseData.type === 'premium' && (
                        <Grid item xs={12}>
                            <TextField label="Price" fullWidth type="number" value={courseData.price} onChange={(e) => setCourseData({ ...courseData, price: e.target.value })} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant="h6">Upload Course Thumbnail</Typography>
                        <Button sx={{ mt: 1 }} variant="contained" component="label" startIcon={<PhotoLibrary />}>
                            Upload Thumbnail
                            <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) => handleThumbnailChange(e.target.files[0])}
                            />
                        </Button>
                        {courseData.thumbnailPreview && (
                            <Box sx={{ marginTop: 2 }}>
                                <Typography variant="caption">Thumbnail Preview:</Typography>
                                <Box sx={{ height: '20vh', marginTop: '0.5rem' }}>
                                    <img src={courseData.thumbnailPreview} alt="Thumbnail Preview" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </Box>
                            </Box>
                        )}
                    </Grid>
                </Grid>

                {/* Modules and videos */}
                {modules?.map((module, moduleIndex) => (
                    <div key={moduleIndex}>
                        <Typography variant="h5" gutterBottom sx={{ marginTop: '1.5rem' }}>Modules({moduleIndex + 1})</Typography>

                        <Box sx={{ border: '1px solid #ccc', padding: '1rem', marginBottom: '1rem' }}>
                            <Button variant='outlined' startIcon={<Delete />} onClick={() => removeModule(moduleIndex)} color="error">
                                Remove Module
                            </Button>

                            <TextField sx={{ mt: 2, mb: 2 }} label="Module Title" fullWidth value={module.title} onChange={(e) => handleModuleChange(moduleIndex, 'title', e.target.value)} />
                            <TextField sx={{ mb: 2 }} label="Module Description" fullWidth multiline rows={2} value={module.description} onChange={(e) => handleModuleChange(moduleIndex, 'description', e.target.value)} />

                            {module?.videos?.map((video, videoIndex) => (
                                <Box key={videoIndex} sx={{ marginBottom: '1rem' }}>
                                    <Typography sx={{ mt: 2, mb: 2 }} variant="h6">Videos({videoIndex + 1})</Typography>

                                    <Button variant='outlined' startIcon={<Delete />} onClick={() => removeVideo(moduleIndex, videoIndex)} color="error">
                                        Remove Video
                                    </Button>

                                    <TextField sx={{ mt: 2, mb: 2 }} label="Video Title" fullWidth value={video.title} onChange={(e) => handleVideoChange(moduleIndex, videoIndex, 'title', e.target.value)} />
                                    <TextField sx={{ mb: 2 }} label="Video Objective" fullWidth value={video.objective} onChange={(e) => handleVideoChange(moduleIndex, videoIndex, 'objective', e.target.value)} />

                                    <Button
                                        variant="contained"
                                        component="label"
                                        sx={{ marginTop: 1 }}
                                        startIcon={<VideoLibrary />}
                                    >
                                        Upload Video
                                        <input
                                            type="file"
                                            accept="video/*"
                                            hidden
                                            onChange={(e) => handleVideoChange(moduleIndex, videoIndex, 'video', e.target.files[0])}
                                        />
                                    </Button>

                                    {video.videoUrl && (
                                        <Box sx={{ marginTop: 2, width: { xs: "100%", md: "50%" } }}>
                                            {/* ReactPlayer for video preview */}
                                            <ReactPlayer
                                                url={video.videoUrl}
                                                controls
                                                width="100%"
                                                height="100%"
                                                playing={false}
                                            />
                                        </Box>
                                    )}

                                    {/* Handle Breakpoints for quizzes */}
                                    {video.breakpoints?.map((breakpoint, breakpointIndex) => (
                                        <Box key={breakpointIndex} sx={{ marginTop: 2 }}>
                                            <TextField
                                                label="Time"
                                                fullWidth
                                                type="number"
                                                value={breakpoint.time}
                                                onChange={(e) => handleBreakpointChange(moduleIndex, videoIndex, breakpointIndex, 'time', e.target.value)}
                                            />
                                            <TextField
                                                sx={{ mt: 2, mb: 2 }}
                                                label="Quiz Question"
                                                fullWidth
                                                value={breakpoint.quiz?.question || ''}
                                                onChange={(e) => handleBreakpointChange(moduleIndex, videoIndex, breakpointIndex, 'quiz', { ...breakpoint.quiz, question: e.target.value })}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            ))}

                            <Button variant="outlined" color="primary" onClick={() => addVideo(moduleIndex)}>
                                Add Video
                            </Button>
                        </Box>
                    </div>
                ))}

                <Box sx={{ mt: 3, width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <Button variant="contained" color="success" onClick={addModule}>Add Module</Button>

                    <Button variant="contained" type="submit" color='success'>
                        Update Course
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default UpdateCourses;
