// style.js
import { keyframes } from '@mui/system';

export const slideInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const fadeIn = {
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  }
};

export const cardStyles = {
  padding: '2vw',
  animation: `${slideInAnimation} 0.5s ease-in-out`,
  transition: 'all 0.3s ease-in-out',
};

export const leftSidebarStyles = {
  minHeight: {
    xs: '30vh',
    md: '90vh',
  },
  padding: '2vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  animation: `${slideInAnimation} 0.5s ease-in-out`,
  transition: 'all 0.3s ease-in-out',
};

export const avatarStyles = {
  width: 100,
  height: 100,
  fontSize: '3rem',
};