import React from 'react'
import { fadeIn } from '../Styles';
import { Box, useMediaQuery } from '@mui/material';
import Navbar from '../home/Navbar';
import Sidebar from '../home/Sidebar';
import { useAuth } from '../../../context/AuthContext';



export default function Structure({ children }) {

    const isSmallScreen = useMediaQuery('(max-width: 700px)');
    const { isSidebarOpen } = useAuth();
    return (
        <><Navbar/>
        <Sidebar/>
        <Box
            component="main"
            sx={{
                padding: "3rem",
                marginTop: "4rem",
                backgroundColor:"#F5F5F5",
                animation: 'fadeIn 1s ease-in-out', ...fadeIn,
                marginLeft: !isSmallScreen && isSidebarOpen ? '300px' : '0px',
                width: !isSmallScreen && isSidebarOpen ? 'calc(100% - 300px)' : '100%',
                transition: 'margin-left 0.3s ease-in-out, width 0.3s ease-in-out',
            }}
            open={isSidebarOpen}
            className="Box"
        >
            {children}
        </Box>

        </>
    )
}