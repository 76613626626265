export const CONST_ROUTE_PATH ={
      HOME:'/',
      SIGN_IN: '/sign-in',
      SIGN_UP: '/sign-up',
      FORGOT_PASSWORD: '/forgot-password',
      USER_PROFILE:"/user-profile",
      USER_LIST:"/user-list",
      USER_DETAILS: '/user-details/:userId',
      DASHBOARD:"/admin/dashboard",
      ABOUT:"/about",
      CREATE_COURSES:"/create-course",
      COURSES:"/courses"
}     