import { createContext, useContext, useState } from 'react';

// Create a context for user-related data
const UserContext = createContext();

// UserContextProvider component to wrap the parts of the app where you want to provide access to the context
export const UserContextProvider = ({ children }) => {
  const [user, setUsers] = useState([]);
   console.log(user)
  return (
    <UserContext.Provider value={{ user, setUsers }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext in components
export const useUserContext = () => useContext(UserContext);
