import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';
import CoursesApiService from '../../../services/CoursesApiServices';
import { useNavigate } from 'react-router-dom';

const CategoryCard = ({ course }) => {
  const { userdetails } = useAuth();
  const [open, setOpen] = useState(false);  // State to control the dialog
const navigate =useNavigate()
  const handleClickOpen = () => {
    setOpen(true);  // Open the dialog when delete is clicked
  };
  const onCourseUpdate = (course)=>{
    navigate(`/course/${course._id}`);

  }
  const handleClose = () => {
    setOpen(false);  // Close the dialog
  };

  const handleDelete = async (courseId) => {
    try {
      await CoursesApiService.deleteCourse(courseId._id);
      alert('Course deleted successfully!');
      handleClose();  // Close the dialog after deleting
    } catch (error) {
      console.error('Failed to delete course:', error);
      alert('Failed to delete course. Please try again.');
    }
  };

  return (
    <>
      <Card
        sx={{
          width: '100%',
          backgroundColor: 'white',
          color: 'black',
          borderRadius: '1vw',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          overflow: 'hidden',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
          minHeight: { xs: "20vh", md: "25vh", lg: '30vh' },
        }}
      >
        {/* Course Image */}
        <Box
          sx={{
            backgroundImage: `url(${course.thumbnailUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '1vw',
            minHeight: { xs: "20vh", md: "25vh", lg: '30vh' },
          }}
        />

        {/* Hover Overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            p: 2,
            opacity: 0,
            transition: 'opacity 0.3s ease',
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <Typography variant="body2" align="center" gutterBottom>
            {course.description}
          </Typography>
          <Box>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'green', fontWeight: 600, fontStyle: "italic", color: 'white', mt: 2, mr: 2 }}
              onClick={() => onCourseUpdate(course)}
            >
              Update
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#FF6F2F', fontWeight: 600, fontStyle: "italic", color: 'white', mt: 2 }}
              onClick={handleClickOpen}  // Open the dialog on click
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Card>

      {/* Course Title and Price */}
      <CardContent sx={{ padding: 2, textAlign: 'center' }}>
        <Typography variant="h5" component="div" fontWeight="bold">
          {course.title}
        </Typography>
        {course.pricing && (
          <Typography variant="body2" color="text.secondary">
            Price: {course.pricing}
          </Typography>
        )}
      </CardContent>

      {/* Confirmation Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Delete"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this course? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDelete(course)} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CategoryCard;
