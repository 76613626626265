import React, { useEffect, useState } from 'react';
import {
    AppBar, Toolbar, Typography, Button, Box, Menu, Fade, Tabs, Tab, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery,
    Divider
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp, Menu as MenuIcon } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { CONST_ROUTE_PATH } from '../../../routes/Routes';
import { useAuth } from '../../../context/AuthContext';
import Logo2 from '../../../assets/Logo.png'

const Header = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Detect screen size
    const navigate = useNavigate();
    const [isScrolled, setIsScrolled] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(false); // For mobile drawer
    const { apiToken, logout } = useAuth();

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            setIsScrolled(offset > 30);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // const handleMenuOpen = (event) => {
    //     setAnchorEl(event.currentTarget);
    //     setOpenMenu(true);
    // };

    // const handleMenuClose = () => {
    //     setAnchorEl(null);
    //     setOpenMenu(false);
    // };

    // const handleTabChange = (event, newValue) => {
    //     setSelectedTab(newValue);
    // };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const drawer = (
        <Box sx={{ width: 250 }} onClick={handleDrawerToggle}>
            <List>


                {/* {menuItems.map((item, index) => (
                    <ListItem button key={index}>
                        <ListItemText primary={item.label} />
                    </ListItem>
                ))} */}
                {apiToken ? (
                    <ListItem button onClick={logout}>
                        <ListItemText primary="Log Out" />
                    </ListItem>
                ) : (
                    <ListItem button component={Link} to={CONST_ROUTE_PATH.SIGN_IN}>
                        <ListItemText primary="Login" />
                    </ListItem>
                )}
            </List>
        </Box>
    );

    return (
        <AppBar
            sx={{
                backgroundColor:"#FFF5EE",
                color:"black",
                width: "100%",

            }}
        >
            <Toolbar sx={{ justifyContent: "space-between"  }}>
                {/* Logo */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        height="80px"
                        src={Logo2}
                        alt="logo"
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/')}
                    />
                    <Box sx={{ ml: "-2rem", textAlign: 'center', color:  '#0F2A47' }}>
                        <Typography variant='h6' sx={{ fontStyle: 'italic', fontWeight: 550, fontSize: "1rem", p: 0 }}>INSTITUTE OF</Typography>
                        <Divider />
                        <Typography variant='h6' sx={{ fontStyle: 'italic', fontWeight: 550, fontSize: "1rem", p: 0 }}>CYBER SECURITY</Typography>
                    </Box>
                </Box>

                {/* Mobile Hamburger Icon */}
                {isMobile ? (
                    <>
                        <IconButton color="inherit" onClick={handleDrawerToggle}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="right"
                            open={drawerOpen}
                            onClose={handleDrawerToggle}
                        >
                            {drawer}
                        </Drawer>
                    </>
                ) : (
                    /* Desktop Navigation Links */
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>


                        {/* Log Out or Login Button */}
                        {apiToken ? (
                            <Button onClick={logout} sx={{ fontWeight: "600", }} color="inherit">Log Out</Button>
                        ) : (
                            <Button component={Link} sx={{ fontWeight: "600", }} to={CONST_ROUTE_PATH.SIGN_IN} color="inherit">Login</Button>
                        )}
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
