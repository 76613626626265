// import React, { useState, useEffect } from 'react';
// import {
//   Box, CssBaseline, Drawer, List, ListItem, ListItemText, Toolbar, AppBar, Typography, IconButton,
// } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import PeopleIcon from '@mui/icons-material/People';
// import SettingsIcon from '@mui/icons-material/Settings';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
// import { Dashboard } from './common/pages/Dashboard';
// import AllUsers from './common/pages/userhandler/AllUsers';
// import UserRequests from './common/pages/userhandler/UsersRequest';
// import BannedUsers from './common/pages/userhandler/BannedUsers';
// // import UserDetails from './UserManagement/UserDetails';
// // import InputControl from './Settings/InputControl';
// // import ImagesControl from './Settings/ImagesControl';
// // import ContentControl from './Settings/ContentControl';

// const drawerWidth = 300;

// function Admin() {
//   const [tabIndex, setTabIndex] = useState(0);
//   const [mobileOpen, setMobileOpen] = useState(true);
//   const theme = useTheme();
//   const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));

//   // Retrieve tabIndex from localStorage on component mount
//   useEffect(() => {
//     const storedIndex = localStorage.getItem('tabIndex');
//     if (storedIndex) {
//       setTabIndex(Number(storedIndex)); // Set tabIndex from localStorage
//     }
//   }, []);

//   const handleTabChange = (index) => {
//     setTabIndex(index);
//     localStorage.setItem('tabIndex', index); // Store tabIndex in localStorage
//   };

//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };

//   const renderContent = () => {
//     switch (tabIndex) {
//       case 0:
//         return <Dashboard />;
//       case 1:
//         return <AllUsers />;
//       case 2:
//         return <UserRequests />;
//       case 3:
//         return <BannedUsers />;
//       // Uncomment these when you implement these components
//       // case 4:
//       //   return <UserDetails />;
//       // case 5:
//       //   return <InputControl />;
//       // case 6:
//       //   return <ImagesControl />;
//       // case 7:
//       //   return <ContentControl />;
//       default:
//         return null;
//     }
//   };

//   const drawer = (
//     <Box sx={{ overflow: 'auto', mt: 10 }}>
//       <List>
//         <Typography variant="h6" sx={{ color: 'white', pl: 2, mb: 1 }}>Analysis</Typography>
//         <ListItem
//           button
//           onClick={() => handleTabChange(0)}
//           sx={{
//             transition: "all 0.3s ease-in",
//             backgroundColor: tabIndex === 0 ? 'white' : 'transparent',
//             color: tabIndex === 0 ? '#008080' : 'white',
//           }}
//         >
//           <DashboardIcon />
//           <ListItemText sx={{ ml: 2 }} primary="Dashboard" />
//         </ListItem>

//         <Typography variant="h6" sx={{ color: 'white', pl: 2, mt: 2, mb: 1 }}>User Management</Typography>
//         {['All Users', 'User Requests', 'Banned Users', 'User Details'].map((text, index) => (
//           <ListItem
//             button
//             key={text}
//             onClick={() => handleTabChange(index + 1)}
//             sx={{
//               transition: "all 0.3s ease-in",
//               backgroundColor: tabIndex === index + 1 ? 'white' : 'transparent',
//               color: tabIndex === index + 1 ? '#008080' : 'white',
//               pl: 4,
//             }}
//           >
//             <PeopleIcon />
//             <ListItemText sx={{ ml: 2 }} primary={text} />
//           </ListItem>
//         ))}

//         <Typography variant="h6" sx={{ color: 'white', pl: 2, mt: 2, mb: 1 }}>Settings</Typography>
//         {['Input Control', 'Images Control', 'Content Control'].map((text, index) => (
//           <ListItem
//             button
//             key={text}
//             onClick={() => handleTabChange(index + 5)}
//             sx={{
//               transition: "all 0.3s ease-in",
//               backgroundColor: tabIndex === index + 5 ? 'white' : 'transparent',
//               color: tabIndex === index + 5 ? '#008080' : 'white',
//               pl: 4,
//             }}
//           >
//             <SettingsIcon />
//             <ListItemText sx={{ ml: 2 }} primary={text} />
//           </ListItem>
//         ))}
//       </List>
//     </Box>
//   );

//   return (
//     <Box sx={{ display: 'flex' }}>
//       <CssBaseline />
//       <AppBar
//         position="fixed"
//         sx={{
//           zIndex: theme.zIndex.drawer + 1,
//           transition: 'width 0.3s ease',
//         }}
//       >
//         <Toolbar sx={{ backgroundColor: "#008080" }}>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             edge="start"
//             onClick={handleDrawerToggle}
//             sx={{ mr: 2 }}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" noWrap>
//             Admin Dashboard
//           </Typography>
//         </Toolbar>
//       </AppBar>

//       <Box component="nav" sx={{ flexShrink: { md: 0 } }}>
//         <Drawer
//           variant={isMediumUp ? 'persistent' : 'temporary'}
//           open={mobileOpen}
//           onClose={handleDrawerToggle}
//           ModalProps={{ keepMounted: true }}
//           sx={{
//             '& .MuiDrawer-paper': {
//               backgroundColor: "#008080",
//               color: "white",
//               boxSizing: 'border-box',
//               width: drawerWidth,
//               zIndex: isMediumUp ? 0 : theme.zIndex.drawer + 2,
//               transition: 'all 0.3s ease',
//             },
//           }}
//         >
//           {drawer}
//         </Drawer>
//       </Box>

//       <Box
//         component="main"
//         sx={{
//           backgroundColor: "#F0F8FF",
//           flexGrow: 1,
//           p: 3,
//           width: `calc(100% - ${mobileOpen && isMediumUp ? drawerWidth : 0}px)`,
//           ml: mobileOpen && isMediumUp ? `${drawerWidth}px` : '0px',
//           transition: 'margin-left 0.3s ease',
//         }}
//       >
//         <Toolbar />
//         <Box sx={{ minHeight: "90vh", p: 3, backgroundColor: "#F0F8FF" }}>
//           {renderContent()}
//         </Box>
//       </Box>
//     </Box>
//   )
// }

// export default Admin;


import React from 'react'
import Navbar from './common/home/Navbar'
import Sidebar from './common/home/Sidebar'
import { Box, useMediaQuery } from '@mui/material'
import { useAuth } from '../context/AuthContext'
import { Dashboard } from './common/pages/Dashboard'

const Admin = () => {
  const isSmallScreen = useMediaQuery('(max-width: 700px)');
  const {isSidebarOpen} = useAuth()
  return (

<>
      <Navbar />
      <Sidebar />

      <Box sx={{ backgroundColor: "#F0F8FF", p:3 }} >
        <Box component='main' sx={{
          marginTop:"4rem",
          marginLeft: !isSmallScreen && isSidebarOpen ? '300px' : '0px',
          width: !isSmallScreen && isSidebarOpen ? 'calc(100% - 300px)' : 'calc(100% - 0px)', // Adjust the fixed width as needed
          transition: 'margin-left 0.3s ease-in-out, width 0.3s ease-in-out',
        }} open={isSidebarOpen} className="Box" >
          <Dashboard/>
        </Box>
        </Box>
      </>
      )
}
      export default Admin